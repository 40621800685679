import { isShortName } from '../../utils/utils'

import getENS, { getRegistrar } from 'apollo/mutations/ens'

import modeNames from '../modes'
import { sendHelper } from '../resolverUtils'

const defaults = {}

const resolvers = {
  Query: {
    async getPriceCurve(_, {tld}) {
      const registrar = getRegistrar(tld)
      return registrar.getPriceCurve()
    },
    async getEthPrice(_, {tld}) {
      const registrar = getRegistrar(tld)
      return registrar.getEthPrice()
    },
    async getRentPrice(_, { tld, label, duration }) {
      const registrar = getRegistrar(tld)
      return registrar.getRentPrice(label, duration)
    },
    async getRentPrices(_, {tld, labels, duration }) {
      const registrar = getRegistrar(tld)
      return labels.length && registrar.getRentPrices(labels, duration)
    },
    async getPremium(_, { tld, name, expires, duration }) {
      const registrar = getRegistrar(tld)
      return registrar.getPremium(name, expires, duration)
    },
    async getTimeUntilPremium(_, { tld, expires, amount }) {
      const registrar = getRegistrar(tld)
      return registrar.getTimeUntilPremium(expires, amount)
    },

    async getMinimumCommitmentAge(tld) {
      try {
        const registrar = getRegistrar(tld)
        const minCommitmentAge = await registrar.getMinimumCommitmentAge()
        return parseInt(minCommitmentAge)
      } catch (e) {
        console.log(e)
      }
    },
    async getMaximumCommitmentAge(tld) {
      try {
        const registrar = getRegistrar(tld)
        const maximumCommitmentAge = await registrar.getMaximumCommitmentAge()
        return parseInt(maximumCommitmentAge)
      } catch (e) {
        console.log(e)
      }
    },
    async checkCommitment(_, {tld, label, secret }) {
      try {
        const registrar = getRegistrar(tld)
        const commitment = await registrar.checkCommitment(label, secret)
        return parseInt(commitment)
      } catch (e) {
        console.log(e)
      }
    }
  },
  Mutation: {
    async commit(_, { tld, label, secret }) {
      const registrar = getRegistrar(tld)
      const tx = await registrar.commit(label, secret)
      return sendHelper(tx)
    },
    async register(_, { tld,label, duration, secret }) {
      console.log('Mutation register')
      
      const registrar = getRegistrar(tld)
      console.log(registrar)
      console.log(tld)
      console.log(label)
      console.log(duration)
      console.log(secret)
      const tx = await registrar.register(label, duration, secret)

      return sendHelper(tx)
    },
    async reclaim(_, { name, address }) {
      console.log('Mutation reclaim')
      const registrar = getRegistrar()
      const tx = await registrar.reclaim(name, address)
      return sendHelper(tx)
    },
    async renew(_, { label, duration }) {
      console.log('Mutation renew')
      const registrar = getRegistrar()
      const tx = await registrar.renew(label, duration)
      return sendHelper(tx)
    },
    async getDomainAvailability(_, { name }) {
      console.log('Mutation getDomainAvailability')
      const registrar = getRegistrar()
      const ens = getENS()
      try {
        const {
          state,
          registrationDate,
          revealDate,
          value,
          highestBid
        } = await registrar.getEntry(name)
        let owner = null
        if (isShortName(name)) {
          cache.writeData({
            data: defaults
          })
          return null
        }

        if (modeNames[state] === 'Owned') {
          owner = await ens.getOwner(`${name}.btc`)
        }

        const data = {
          domainState: {
            name: `${name}.btc`,
            state: modeNames[state],
            registrationDate,
            revealDate,
            value,
            highestBid,
            owner,
            __typename: 'DomainState'
          }
        }

        cache.writeData({ data })

        return data.domainState
      } catch (e) {
        console.log('Error in getDomainAvailability', e)
      }
    },
    async setRegistrant(_, { name, address }) {
      console.log('Mutation setRegistrant')
      const registrar = getRegistrar()
      const tx = await registrar.transferOwner(name, address)
      return sendHelper(tx)
    },
    async submitProof(_, { tld, name, parentOwner }) {
      console.log('Mutation submitProof')
      const registrar = getRegistrar(tld)
      const tx = await registrar.submitProof(name, parentOwner)
      return sendHelper(tx)
    },
    async renewDomains(_, { labels, duration }) {
      console.log('Mutation renewDomains')
      const registrar = getRegistrar()
      const tx = await registrar.renewAll(labels, duration)
      return sendHelper(tx)
    }
  }
}

export default resolvers

export { defaults }
