import { setupHI } from '@hidomains/ui'
import { isENSReadyReactive } from '../reactiveVars'

const INFURA_ID =
  window.location.host === 'app.highfive.name'
    ? '90f210707d3c450f847659dc9a3436ea'
    : '58a380d3ecd545b2b5b3dad5d2b18bf0'

let ens = {},
  registrar = {},
  registrarNico = {},
  registrarEthereum = {},
  registrarPunk = {},
  registrarBayc = {},
  registrarApe = {},
  registrarChina = {},
  registrarUsa = {},
  registrarUsdt = {},
  registrarDoge = {},
  registrarFootball = {},
  registrarWorldcup = {},
  registrarClub = {},
  registrarVC = {},
  registrarMimic = {},
  registrarMfer = {},
  registrarSos = {},
  registrarSuc = {},
  registrarWamao = {},
  registrarDali = {},
  registrarPeople = {},
  registrarMahsa = {},
  registrarDao = {},
  registrarGirl = {},
  registrarLady = {},
  registrarSbf = {},
  registrarCz = {},
  registrarLoser = {},
  registrarAi = {},
  ensRegistryAddress = undefined

export async function setup({
  reloadOnAccountsChange,
  enforceReadOnly,
  enforceReload,
  customProvider,
  ensAddress
}) {
  let option = {
    reloadOnAccountsChange: false,
    enforceReadOnly,
    enforceReload,
    customProvider,
    ensAddress
  }
  if (enforceReadOnly) {
    option.infura = INFURA_ID
  }

  const {
    ens: ensInstance,
    registrar: registrarInstance,
    registrarNico: registrarNicoInstance,
    registrarEthereum: registrarEthereumInstance,
    registrarPunk: registrarPunkInstance,
    registrarBayc: registrarBaycInstance,
    registrarApe: registrarApeInstance,
    registrarChina: registrarChinaInstance,
    registrarUsa: registrarUsaInstance,
    registrarUsdt: registrarUsdtInstance,
    registrarDoge: registrarDogeInstance,
    registrarFootball: registrarFootballInstance,
    registrarWorldcup: registrarWorldcupInstance,
    registrarClub: registrarClubInstance,
    registrarVC: registrarVCInstance,
    registrarMimic: registrarMimicInstance,
    registrarMfer: registrarMferInstance,
    registrarSos: registrarSosInstance,
    registrarSuc: registrarSucInstance,
    registrarWamao: registrarWamaoInstance,
    registrarDali: registrarDaliInstance,
    registrarPeople: registrarPeopleInstance,
    registrarMahsa: registrarMahsaInstance,
    registrarDao: registrarDaoInstance,
    registrarGirl: registrarGirlInstance,
    registrarLady: registrarLadyInstance,
    registrarSbf: registrarSbfInstance,
    registrarCz: registrarCzInstance,
    registrarLoser: registrarLoserInstance,
    registrarAi: registrarAiInstance,
    providerObject
  } = await setupHI(option)
  ens = ensInstance
  registrar = registrarInstance
  registrarNico = registrarNicoInstance
  registrarEthereum = registrarEthereumInstance
  registrarPunk = registrarPunkInstance
  registrarBayc = registrarBaycInstance
  registrarApe = registrarApeInstance
  registrarChina = registrarChinaInstance
  registrarUsa = registrarUsaInstance
  registrarUsdt = registrarUsdtInstance
  registrarDoge = registrarDogeInstance
  registrarFootball = registrarFootballInstance
  registrarWorldcup = registrarWorldcupInstance
  registrarClub = registrarClubInstance
  registrarVC = registrarVCInstance
  registrarMimic = registrarMimicInstance
  registrarMfer = registrarMferInstance
  registrarSos = registrarSosInstance
  registrarSuc = registrarSucInstance
  registrarWamao = registrarWamaoInstance
  registrarDali = registrarDaliInstance
  registrarPeople = registrarPeopleInstance
  registrarMahsa = registrarMahsaInstance
  registrarDao = registrarDaoInstance
  registrarGirl = registrarGirlInstance
  registrarLady = registrarLadyInstance
  registrarSbf = registrarSbfInstance
  registrarCz = registrarCzInstance
  registrarLoser = registrarLoserInstance
  registrarAi = registrarAiInstance
  ensRegistryAddress = ensAddress
  isENSReadyReactive(true)
  return {
    ens,
    registrar,
    registrarNico,
    registrarEthereum,
    registrarPunk,
    registrarBayc,
    registrarApe,
    registrarChina,
    registrarUsa,
    registrarUsdt,
    registrarDoge,
    registrarFootball,
    registrarWorldcup,
    registrarClub,
    registrarVC,
    registrarMimic,
    registrarMfer,
    registrarSos,
    registrarSuc,
    registrarWamao,
    registrarDali,
    registrarPeople,
    registrarMahsa,
    registrarDao,
    registrarGirl,
    registrarLady,
    registrarSbf,
    registrarCz,
    registrarLoser,
    registrarAi,
    providerObject
  }
}

export function getRegistrar(tld = 'btc') {
  if (tld === 'nico') {
    return registrarNico
  } else if (tld === 'ethereum') {
    return registrarEthereum
  } else if (tld === 'punk') {
    return registrarPunk
  } else if (tld === 'bayc') {
    return registrarBayc
  } else if (tld === 'ape') {
    return registrarApe
  } else if (tld === 'china') {
    return registrarChina
  } else if (tld === 'usa') {
    return registrarUsa
  } else if (tld === 'usdt') {
    return registrarUsdt
  } else if (tld === 'doge') {
    return registrarDoge
  } else if (tld === 'football') {
    return registrarFootball
  } else if (tld === 'worldcup') {
    return registrarWorldcup
  } else if (tld === 'club') {
    return registrarClub
  } else if (tld === 'vc') {
    return registrarVC
  } else if (tld === 'mimic') {
    return registrarMimic
  } else if (tld === 'mfer') {
    return registrarMfer
  } else if (tld === 'sos') {
    return registrarSos
  } else if (tld === 'suc') {
    return registrarSuc
  } else if (tld === 'wamao') {
    return registrarWamao
  } else if (tld === 'dali') {
    return registrarDali
  } else if (tld === 'people') {
    return registrarPeople
  } else if (tld === 'mahsa') {
    return registrarMahsa
  } else if (tld === 'dao') {
    return registrarDao
  } else if (tld === 'girl') {
    return registrarGirl
  } else if (tld === 'lady') {
    return registrarLady
  } else if (tld === 'sbf') {
    return registrarSbf
  } else if (tld === 'cz') {
    return registrarCz
  } else if (tld === 'loser') {
    return registrarLoser
  } else if (tld === 'ai') {
    return registrarAi
  } else {
    return registrar
  }
}

export function getEnsAddress() {
  return ensRegistryAddress
}

export default function getENS() {
  return ens
}
